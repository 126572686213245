<template>
    <div class="card-list">
        <div class="card-item cursor" v-for="(item, index) in cardList" :key="index" @click="handleClick(item)">
            <img style="width:72px; height: 72px;" v-real-img="item.Logo" src="@/assets/images/default_logo.png" alt="" />
            <h3>{{ item.EnterpriseName }}</h3>
            <div class="tags">
                {{ item.NatureName }}<span v-if="item.NatureName && item.IndustryName"> | </span> {{ item.IndustryName }}
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "SchoolCard",
        props: {
            cardList: {
                type: Array,
                default: () => []
            },
            isType: {
                type: Boolean,
                default: false
            }
        },
        methods: {
            handleClick(item) {
                sessionStorage.setItem("enterpriseId", item.Id);
                this.$router.push("/cooperativeFirm/firmInfo?id=" + item.Id);
            }
        }
    };
</script>

<style lang="scss" scoped>
    .cursor {
        .tags {
            height: 20px;
        }
    }
</style>