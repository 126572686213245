<template>
  <div class="job-container">
    <div class="g-section">
      <div class="service-banner">
        <div class="news-tips">
          <div>合作企业</div>
          <div class="news-tips-en">COOPERATIVE ENTERPRISE</div>
        </div>
      </div>
    </div>
    <div class="g-section">
      <div class="g-wrap mt-20 p-20" style="border-radius: 8px 8px 0px 0px;">
        <!-- <el-cascader class="mr-10" v-model="searchArea" :options="optionsArea" placeholder="地区"></el-cascader>
        <el-cascader class="mr-10" v-model="searchArea" :options="optionsArea" placeholder="行业"></el-cascader>
        <el-cascader class="mr-10" v-model="searchArea" :options="optionsArea" placeholder="岗位"></el-cascader> -->
        <el-input placeholder="请输入关键字搜索" v-model="queryData.keyword" class="mr-10 input-with-select">
        </el-input>
        <el-button type="primary" icon="el-icon-search" @click="search">搜索</el-button>
      </div>
    </div>
    <div class="g-section">
      <div class="g-wrap flex-column pr-20 pl-20 pb-20 border-bottom">
        <div :class="
            `${isExpand ? 'condition-section' : 'condition-section-hide'}`
          ">
          <div class="job-condition">
            <span class="job-condition-title">所属城市:</span>
            <div class="job-condition-wrap">
              <span class="job-condition-item" v-bind:class="{ checked: queryData.City === '' }" @click="handleAreaAll">全国</span>
              <span v-for="(item, index) in areaAllList" :key="index" class="job-condition-item" @click="handleAreaItem(item.Code)" v-bind:class="{
                  checked: queryData.City == item.Code
                }">{{ item.Name }}</span>
            </div>
            <span class="job-condition-more" @click="AreaDialogVisible = true">更多+</span>
          </div>
          <div class="job-condition">
            <span class="job-condition-title">期望行业:</span>
            <div class="job-condition-wrap" :style="{ height: isIndustryMore ? 'auto' : '27px' }">
              <span class="job-condition-item" v-bind:class="{ checked: queryData.Industry === '' }" @click="handleIndustryAll">不限</span>
              <span v-for="{ label, value } in IndustryOptions" :key="value" class="job-condition-item" @click="handleIndustryItem(value)" v-bind:class="{
                  checked: queryData.Industry === value
                }">{{ label }}</span>
            </div>
            <span class="job-condition-more" @click="isIndustryMore = !isIndustryMore">{{ isIndustryMore ? "关闭" : "更多+" }}</span>
          </div>
          <div class="job-condition">
            <span class="job-condition-title">公司性质:</span>
            <div class="job-condition-wrap">
              <span class="job-condition-item" v-bind:class="{ checked: queryData.Nature == '' }" @click="handleNatureAll">不限</span>
              <span v-for="{ label, value } in NatureOptions" :key="value" class="job-condition-item" @click="handleNatureChange(value)" :class="{
                  checked: queryData.Nature === value
                }">{{ label }}</span>
            </div>
          </div>
          <div class="job-condition">
            <span class="job-condition-title">公司规模:</span>
            <div class="job-condition-wrap">
              <span class="job-condition-item" v-bind:class="{ checked: queryData.Scale == '' }" @click="handleScaleAll">不限</span>
              <span v-for="{ label, value } in ScaleOptions" :key="value" class="job-condition-item" @click="handleScaleChange(value)" :class="{
                  checked: queryData.Scale === value
                }">{{ label }}</span>
            </div>
          </div>
        </div>
        <!-- <div class="job-condition-btn mb-10">
          <el-button
            type="text"
            class="color-primary"
            :icon="`${isExpand ? 'el-icon-arrow-up' : 'el-icon-arrow-down'}`"
            @click="isExpand = !isExpand"
            >{{ isExpand ? "收起" : "更多" }}
          </el-button>
        </div> -->
      </div>
    </div>
    <div class="g-section mb-20">
      <div class="g-wrap flex-column p-0" style="border-radius: 0px 0px 8px 8px;">
        <!-- <div class="sel-condition">
          <div>
            <span class="sel-condition-title">已选条件:</span>
            <div class="sel-condition-wrap ">
            </div>
          </div>
          <span>共找到<span class="job-title">888+</span>个人才</span>
        </div> -->
        <div v-if="list.length > 0" class="talent-list" v-loading="loading">
          <school-card :cardList="list" />
          <!-- <list :list="list"></list> -->
          <div class="loading" v-if="totalCount > 10">
            <Pagination :total="totalCount" :limit="16" :layout="'prev, pager, next'" :autoScroll="false" @pagination="areaPagination" />
          </div>
        </div>
        <div v-else class="talent-list" v-loading="loading">
          <div class="noListData">当前无数据~</div>
        </div>
      </div>
    </div>
    <el-dialog title="选择城市" :visible.sync="AreaDialogVisible" width="50%" :before-close="areaHandleClose">
      <el-tabs :tab-position="'left'" style="height: 600px;">
        <el-tab-pane v-for="(item, index) in AreaOptions" :key="index" :label="item.Name" stretch>
          <div class="label-area">
            <div v-for="(el, k) in item.children" :key="k" class="area-item">
              <span class="area-item-span" @click="handleAreaItem(el.Code, index)">
                {{ el.Name }}</span>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
      <span slot="footer" class="dialog-footer"> </span>
    </el-dialog>
  </div>
</template>

<script>
import SchoolCard from "./components/SchoolCard";
import Pagination from "@/components/Pagination";
import { mapGetters, mapState } from "vuex";
import { getPageList } from "@/api/enterprise/enterpriseInfo";
import { dateTimeFilter } from "@/filters";
// import { StaffTypeEnum } from "@/utils/constant";
// import List from "@/components/TalentList";
export default {
  components: {
    SchoolCard,
    Pagination
  },
  data() {
    return {
      loading: false,
      AreaDialogVisible: false,
      dialogVisible: false,
      // SalaryOptions: [],
      // ExperienceOptions: [],
      // EducationOptions: [],
      AreaOptions: [],
      IndustryOptions: [],
      NatureOptions: [],
      ScaleOptions: [],
      searchArea: [],
      isIndustryMore: false,
      iscityMore: false,

      queryData: {
        KeyWord: null,
        PageSize: 16,
        PageIndex: 1,
        City: "", //城市Code-string
        Industry: "", // 行业-string
        Nature: "", // 公司性质
        Scale: "" // 公司规模
      },
      areaAllList: [],
      list: [],
      keyword: "",
      pageIndex: 0,
      pageSize: 5,
      totalCount: 0,
      Resume: {
        ResumeCareerDtoList: [],
        ResumeExpectDtoList: [],
        ResumeProjectDtoList: []
      },
      isExpand: true,
      currentSalary: "",
      currentExperience: "",
      currentEducation: "",
      UnitId: undefined
    };
  },
  computed: {
    ...mapGetters(["employee"]),
    ...mapState("common", {
      AreaList: state => state.Area, //
      IndustryList: state => state.Industry, // 行业
      NatureList: state => state.Nature, //
      ScaleList: state => state.Scale // 公司规模
    }),
    noMore() {
      return this.loading == false && this.list.length == this.totalCount;
    },
    formartDate() {
      return (data, formate) => dateTimeFilter(data, formate);
    }
  },
  mounted() {
    this.search();
    this.getCondition();
  },
  methods: {
    async search() {
      this.loading = true;
      await getPageList(this.queryData).then(
        ({ Result: { Data, TotalCount } }) => {
          this.list = Data;
          this.totalCount = TotalCount;
          this.loading = false;
        }
      );
    },
    getCondition() {
      this.toTree(this.AreaList)[0].children.forEach(el => {
        this.AreaOptions.push(el);
      });
      this.areaAllList = this.AreaOptions[
        Math.round(Math.random() * 34)
      ].children;
      this.IndustryOptions = this.IndustryList.map(({ Text, Value }) => ({
        label: Text,
        value: Value
      }));
      this.NatureOptions = this.NatureList.map(({ Text, Value }) => ({
        label: Text,
        value: Value
      }));
      this.ScaleOptions = this.ScaleList.map(({ Text, Value }) => ({
        label: Text,
        value: Value
      }));
    },
    // 转为树形结构
    toTree(data) {
      let result = [];
      if (!Array.isArray(data)) {
        return result;
      }
      data.forEach(item => {
        delete item.children;
      });
      let map = {};
      data.forEach(item => {
        map[item.Code] = item;
      });
      data.forEach(item => {
        let parent = map[item.ParentCode];
        if (parent) {
          (parent.children || (parent.children = [])).push(item);
        } else {
          result.push(item);
        }
      });
      return result;
    },

    // 城市点击
    handleAreaItem(Code, index) {
      this.queryData.City = Code;
      if (index) this.areaAllList = this.AreaOptions[index].children;
      this.search();
      this.AreaDialogVisible = false;
    },
    handleAreaAll() {
      this.queryData.City = "";
      this.search();
    },
    areaHandleClose() {
      this.AreaDialogVisible = false;
    },
    areaPagination(val) {
      this.queryData.PageIndex = val.page;
      this.search();
    },
    // 行业点击
    handleIndustryItem(val) {
      this.queryData.Industry = val;
      this.search();
    },
    handleIndustryAll() {
      this.queryData.Industry = "";
      this.search();
    },
    // 公司性质
    handleNatureAll() {
      this.queryData.Nature = "";
      this.search();
    },
    handleNatureChange(val) {
      this.queryData.Nature = val;
      this.search();
    },
    // 公司规模
    handleScaleAll() {
      this.queryData.Scale = "";
      this.search();
    },
    handleScaleChange(val) {
      this.queryData.Scale = val;
      this.search();
    }
  }
};
</script>

<style lang="scss" scoped>
.job-container {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  flex: 1;
  .service-banner {
    display: flex;
    width: 100%;
    height: 160px;
    background-color: #d3dfe8;
    background-image: url('~@/assets/images/banner/information.png');
    justify-content: center;
    align-items: center;
    .news-tips {
      width: 1100px;
      font-size: 32px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #ffffff;
      line-height: 45px;
      &-en {
        font-size: 20px;
        font-family: HelveticaNeue;
        color: rgba(255, 255, 255, 0.35);
        line-height: 24px;
      }
    }
  }
  .g-section {
    .g-wrap {
      background: #fff;
    }
  }
}
.job-banner {
  display: flex;
  width: 100%;
  height: 200px;
  background-color: #d3dfe8;
  justify-content: center;
  align-items: center;
}
.border-bottom {
  border-bottom: 1px solid #eee;
}
.condition-section {
  flex-direction: column;
  padding-top: 0px;
}
.talent-list {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  min-height: 260px;
  .noListData {
    width: 100%;
    height: 100%;
    color: #3d5afe;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.talent-item {
  display: flex;
  flex-direction: column;
  border-radius: 3px;
  align-items: center;
  margin-bottom: 20px;
  padding: 16px;
  box-sizing: border-box;
  border: 1px solid #eee;
  font-size: 14px;
  color: #999;
  cursor: pointer;
  &:hover {
    box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.05);
  }
}

.item-row {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.condition-section-hide {
  display: none;
}
.job-condition {
  display: flex;
  // align-items: center;
  margin-top: 10px;
  .job-condition-title {
    width: 80px;
    font-size: 16px;
    font-weight: 600;
    margin-right: 10px;
  }
  .job-condition-wrap {
    user-select: none;
    display: flex;
    white-space: nowrap; /*一行显示*/
    overflow: hidden; /*超出部分隐藏*/
    text-overflow: ellipsis; /*用...代替超出部分*/
    width: 80%;
    height: 27px;
    overflow: hidden;
    flex-wrap: wrap;
    /deep/ .el-cascader {
      width: 80% !important;
    }
    .job-condition-item {
      font-family: PingFangSC-Regular, PingFang SC;
      padding: 4px 8px;
      border-radius: 4px;
      margin-right: 10px;
      font-weight: 400;
      font-size: 14px;
      &:hover {
        cursor: pointer;
        background: rgba(61, 90, 254, 0.1);
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #3d5afe;
      }
    }
    .checked {
      background-color: #3d5afe;
      color: #ffffff;
    }
  }
  .job-condition-more {
    user-select: none;
    width: 40px;
    height: 27px;
    cursor: pointer;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #3d5afe;
    line-height: 27px;
  }
}
.job-condition-btn {
  display: flex;
  justify-content: center;
  align-items: center;
}
.sel-condition {
  border: 1px solid #b3d8ff;
  background-color: #ecf5ff;
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-radius: 3px;
  box-sizing: border-box;
  padding: 10px;
  .sel-condition-title {
    color: #3d5afe;
    font-size: 16px;
    font-weight: 600;
    margin-right: 10px;
  }
}
.job-title {
  color: #3d5afe;
  font-size: 18px;
}
.table-bottom {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.icon-collect {
  color: #3d5afe;
  font-size: 18px;
  cursor: pointer;
}
.dialog-content {
  height: 400px;
  display: flex;
  padding: 38px 28px;
  flex-direction: column;
  overflow-y: auto;
  .content-title {
    margin-bottom: 16px;
    &::before {
      content: '';
      vertical-align: -3px;
      border-radius: 2px;
      display: inline-block;
      width: 3px;
      height: 16px;
      background-color: #3d5afe;
      margin-right: 5px;
    }
  }
}
.loading {
  display: flex;
  justify-content: center;
  text-align: center;
}
.label-area {
  width: 100%;
  height: 600px;
  overflow: auto;

  .area-item {
    height: 28px;
    margin-top: 16px;
    margin-bottom: 16px;
    .area-item-span {
      transition: all 0.1s;
      user-select: none;
      cursor: pointer;
      padding: 4px 14px 4px 14px;
      height: 28px;
      line-height: 28px;
      background: rgba(61, 90, 254, 0.1);
      border-radius: 4px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #3d5afe;
      line-height: 20px;
      &:hover {
        background: #3d5afe;
        color: #fff;
        font-size: 18px;
        font-weight: 600;
      }
    }
  }
}
/deep/ .el-tabs__nav {
  height: 100%;
  overflow: auto;
}
</style>
